<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <!------------ box header ------------->
      <div class="flex-none border-t-0 border-b border-l-0 border-r-0 border-solid d-theme-border-grey-light">
        <div class="flex flex-wrap space-x-3 px-2 py-1 vx-card shadow-none">
          <div class="flex items-center">
            <label class="mr-1 text-xs whitespace-no-wrap">Proyek</label>
            <vs-select class="vs-select-small" v-model="filter.id_proyek">
              <vs-select-item class="vs-select-small" text="Semua"/>
              <vs-select-item class="vs-select-small" v-for="(it, index) in proyeks" :key="index" :value="it.id" :text="`${it.kode} - ${it.nama}`"/>
            </vs-select>
          </div>
          <div class="flex items-center">
            <vs-input class="w-full" type="text" placeholder="Search" v-model="filter.search" @keyup.enter="getData"/>
          </div>
          <div class="flex items-center">
            <vs-button class="px-3 text-xs text-center" color="primary" :disabled="loading.getData" @click="onFilter">
              {{ loading.getData ? 'Loading...' : 'Filter' }}
            </vs-button>
          </div>
        </div>
        <div class="flex justify-between border-t border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light" style="background-color: rgba(121,121,121,0.1);">
          <div class="flex items-center justify-start space-x-3 px-2 h-8">
            <p class="text-xs font-medium text-dark text-bold">{{ this.data.items.length }} Result</p>
            <QueryEditor v-if="$can('view_query')" code="PENGAKUAN_PENDAPATAN_UNIT"/>
          </div>
        </div>
      </div>

      <!----------- box content ------------->
      <div class="flex-1 border-t-0 border-b border-l-0 border-r-0 border-solid d-theme-border-grey-light">
        <div class="box-content-height overflow-auto">
          <div class="tableSticky">
            <table class="w-full h-full border-collapse">
              <colgroup>
                <col class="w-16"/>
                <col class="w-24"/>
                <col class="w-24"/>
                <col class="w-32"/>
                <col class="w-32"/>
                <col class="w-32"/>
                <col class="w-32"/>
                <col class="w-32"/>
                <col class="w-32"/>
                <col class="w-32"/>
              </colgroup>
              <!-----------TABLE HEAD------------>
              <thead class="font-extrabold border border-solid d-theme-border-grey-light">
                <tr class="d-theme-dark-bg border border-solid d-theme-border-grey-light">
                  <th rowspan="2" class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">Action</th>
                  <th rowspan="2" class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">No.Unit</th>
                  <th rowspan="2" class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">No.Kontrak</th>
                  <th colspan="2" class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">HPP Tanah</th>
                  <th colspan="2" class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">HPP Bangunan</th>
                  <th colspan="2" class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">Total HPP</th>
                  <th rowspan="2" class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">Revenue</th>
                </tr>
                <tr class="d-theme-dark-bg border border-solid d-theme-border-grey-light">
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">HPP RAB</th>
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">HPP REAL</th>
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">HPP RAB</th>
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">HPP REAL</th>
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">HPP RAB</th>
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">HPP REAL</th>
                </tr>
              </thead>
              <!-----------TABLE BODY------------>
              <tbody>
                <tr v-for="(item, index) in data.items" :key="index" class="cursor-pointer hover:font-medium">
                  <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-center" @click.stop>
                    <vs-button size="small" class="px-2" icon-pack="feather" icon="icon-check" @click="showModalUnitPosting(item)" v-if="$can('posting_pengakuan_pendapatan')">Posting</vs-button>
                  </td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.kode_proyek }} - {{ item.no_unit }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.no_kontrak }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.hpp_tanah_rab | idr }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.hpp_tanah_real | idr }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.hpp_bangunan_rab | idr }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.hpp_bangunan_real | idr }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ parseFloat(item.hpp_tanah_rab) + parseFloat(item.hpp_bangunan_rab) | idr }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ parseFloat(item.hpp_tanah_real) + parseFloat(item.hpp_bangunan_real) | idr }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.total_revenue | idr }}</td>
                </tr>
              <!--spacer-->
              <tr v-if="data.items.length > 0" class="h-full">
                <td colspan="10"></td>
              </tr>
              </tbody>
              <!-----------TABLE FOOT------------>
              <tfoot v-if="data.items.length > 0">
                <tr class="d-theme-dark-bg border border-solid d-theme-border-grey-light">
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right"></th>
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right"></th>
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">TOTAL</th>
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ summary.grandTotalHppRabTanah | idr }}</th>
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ summary.grandTotalHppRealTanah | idr }}</th>
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ summary.grandTotalHppRabBangunan | idr }}</th>
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ summary.grandTotalHppRealBangunan | idr }}</th>
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ summary.grandTotalHppRab | idr }}</th>
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ summary.grandTotalHppReal | idr }}</th>
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ summary.grandTotalRevenue | idr }}</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>

      <!------------ box footer ------------->
      <div class="flex-none">
        <div class="flex flex-wrap items-center p-2 justify-between">
          <div class="h-8"></div>
        </div>
      </div>
    </div>

    <!--modals-->
    <PengakuanPendapatanUnitPosting
      :isActive.sync="modalUnitPosting.active"
      :idKontrak="modalUnitPosting.idKontrak"
      :item="modalUnitPosting.item"
      @success="refresh"/>
  </div>
</template>

<script>
import ProyekRepository from '@/repositories/master/proyek-repository'
import PengakuanPendapatanRepository from '@/repositories/accounting/pengakuan-pendapatan-repository'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

export default {
  name: 'TabUnit',
  props: ['isActive'],
  components: {
    PengakuanPendapatanUnitPosting: () => import('@/views/pages/accounting/pengakuan-pendapatan/part/PengakuanPendapatanUnitPosting'),
    QueryEditor: () => import('@/views/components/query-editor/QueryEditor')
  },
  watch: {
    isActive: {
      immediate: true,
      handler (val) {
        if (val && !this.isDataInited) this.initData()
      }
    }
  },
  computed: {
    summary () {
      return {
        grandTotalRevenue: _.sumBy(this.data.items, item => parseFloat(item.total_revenue || '0')),
        grandTotalHppRabTanah: _.sumBy(this.data.items, item => parseFloat(item.hpp_tanah_rab || '0')),
        grandTotalHppRealTanah: _.sumBy(this.data.items, item => parseFloat(item.hpp_tanah_real || '0')),
        grandTotalHppRabBangunan: _.sumBy(this.data.items, item => parseFloat(item.hpp_bangunan_rab || '0')),
        grandTotalHppRealBangunan: _.sumBy(this.data.items, item => parseFloat(item.hpp_bangunan_real || '0')),
        grandTotalHppRab: _.sumBy(this.data.items, item => parseFloat((item.hpp_tanah_rab + item.hpp_bangunan_rab) || '0')),
        grandTotalHppReal: _.sumBy(this.data.items, item => parseFloat((item.hpp_tanah_real + item.hpp_bangunan_real) || '0'))
      }
    }
  },
  data () {
    return {
      isDataInited: false,
      proyeks: [],
      loading: {
        getData: false
      },
      filter: {
        id_proyek: null,
        search: null
      },
      data: {
        items: []
      },
      modalUnitPosting: {
        active: false,
        idKontrak: null,
        item: {}
      }
    }
  },
  methods: {
    initData () {
      this.refresh()
      this.getProyeks()
      this.isDataInited = true
    },

    onFilter () {
      this.getData()
    },

    getProyeks () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getData () {
      this.loading = true

      const params = {
        type: 'UNIT',
        id_proyek: this.filter.id_proyek,
        search: this.filter.search
      }
      PengakuanPendapatanRepository.getPendapatan(params)
        .then(response => {
          this.data.items = _.map(response.data.data, item => {
            item.uuid = uuid()
            return item
          })
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading = false
        })
    },

    isTabActive (tabCode) {
      return tabCode === this.tabs.active
    },

    showModalUnitPosting (item) {
      this.modalUnitPosting.idKontrak = item.id_kontrak
      this.modalUnitPosting.item = item
      this.modalUnitPosting.active = true
    },

    refresh () {
      this.data.items = []
      this.getData()
    }
  }
}
</script>

<style scoped>
/*navbar floating*/
.navbar-floating .box-content-height {
  height: calc(100vh - 314px);
}
.navbar-floating.footer-sticky .box-content-height {
  height: calc(100vh - 337px);
}
.navbar-floating.footer-hidden .box-content-height {
  height: calc(100vh - 283px);
}

/*navbar sticky*/
.navbar-sticky .box-content-height {
  height: calc(100vh - 300px);
}
.navbar-sticky.footer-sticky .box-content-height {
  height: calc(100vh - 323px);
}
.navbar-sticky.footer-hidden .box-content-height {
  height: calc(100vh - 269px);
}

/*navbar static*/
.navbar-static .box-content-height {
  height: calc(100vh - 282px);
}
.navbar-static.footer-sticky .box-content-height {
  height: calc(100vh - 305px);
}
.navbar-static.footer-hidden .box-content-height {
  height: calc(100vh - 251px);
}

/*navbar hidden*/
.navbar-hidden .box-content-height {
  height: calc(100vh - 237px);
}
.navbar-hidden.footer-sticky .box-content-height {
  height: calc(100vh - 260px);
}
.navbar-hidden.footer-hidden .box-content-height {
  height: calc(100vh - 206px);
}

/*table sticky*/
.tableSticky {
  overflow: auto;
  height: calc(100vh - 300px);
}
.table-sticky thead {
  position: sticky;
  top: -1px;
  z-index: 1;
}
.table-sticky tfoot {
  position: sticky;
  bottom: -1px;
  z-index: 1;
}
</style>

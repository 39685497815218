import { render, staticRenderFns } from "./TabUnit.vue?vue&type=template&id=03528965&scoped=true&"
import script from "./TabUnit.vue?vue&type=script&lang=js&"
export * from "./TabUnit.vue?vue&type=script&lang=js&"
import style0 from "./TabUnit.vue?vue&type=style&index=0&id=03528965&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03528965",
  null
  
)

export default component.exports
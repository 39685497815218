const getDefaultState = () => {
  return {
    // tab ap list
    tabUnit: {},

    // tab payment voucher
    tabIpl: {}
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    SET_TAB_UNIT (state, payload) {
      state.tabApList = payload
    },
    SET_TAB_IPL (state, payload) {
      state.tabPv = payload
    },
    RESET_STATE (state) {
      Object.assign(state, getDefaultState())
    }
  },

  actions: {}
}

<template>
  <div class="no-scroll-content flex flex-col border border-solid d-theme-border-grey-light">
    <!--tabs-->
    <div class="flex-none border-t-0 border-b border-l-0 border-r-0 border-solid d-theme-border-grey-light">
      <div class="p-2 vx-card shadow-none rounded-none">
        <div class="flex h-auto">
          <ul class="flex items-center text-xs overflow-x-auto">
            <li v-for="(tab, index) in tabs.items"
                :key="index"
                :class="{'text-primary font-bold bg-primary-transparent-25': tabs.active === tab.code, 'hover-bg-primary-transparent-25': tabs.active !== tab.code}"
                @click="tabs.active = tab.code"
                class="cursor-pointer px-2 py-1 select-none whitespace-no-wrap border-solid d-theme-border-grey-light border-t-0 border-b-0 border-l-0 border-r">
              {{ tab.title }}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!--content-->
    <div class="flex-grow">
      <div class="h-full d-theme-dark-bg">
        <TabUnit v-show="tabs.active === 'UNIT'" :is-active="tabs.active === 'UNIT'"/>
        <TabIpl v-show="tabs.active === 'IPL'" :is-active="tabs.active === 'IPL'"/>
      </div>
    </div>
  </div>
</template>

<script>
import moduleAp from '@/store/modules/accounting/pengakuan-pendapatan/pengakuan-pendapatan.store'
import TabUnit from '@/views/pages/accounting/pengakuan-pendapatan/tabs/TabUnit'
import TabIpl from '@/views/pages/accounting/pengakuan-pendapatan/tabs/TabIpl'

export default {
  name: 'PengakuanPendapatan',
  components: {
    TabUnit,
    TabIpl
  },
  data () {
    return {
      tabs: {
        active: 'UNIT',
        items: [
          { code: 'UNIT', title: 'Kontrak Unit' },
          { code: 'IPL', title: 'IPL Estate' }
        ]
      }
    }
  },
  beforeCreate () {
    this.$store.registerModule(['accounting', 'pengakuan-pendapatan'], moduleAp)
  },
  beforeDestroy () {
    this.$store.commit('accounting/pengakuan-pendapatan/RESET_STATE')
    this.$store.unregisterModule(['accounting', 'pengakuan-pendapatan'])
  }
}
</script>

<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <!------------ box header ------------->
      <div class="flex-none border-t-0 border-b border-l-0 border-r-0 border-solid d-theme-border-grey-light">
        <div class="flex flex-wrap space-x-3 px-2 py-1 vx-card shadow-none">
          <div class="flex items-center">
            <label class="mr-1 text-xs whitespace-no-wrap">Proyek</label>
            <vs-select class="vs-select-small" v-model="filter.id_proyek">
              <vs-select-item class="vs-select-small" v-for="(it, index) in proyeks" :key="index" :value="it.id" :text="`${it.kode} - ${it.nama}`"/>
            </vs-select>
          </div>
          <div class="flex items-center">
            <label class="mr-1 text-xs whitespace-no-wrap">Periode</label>
            <vx-input-group class="w-52">
              <template slot="prepend">
                <vx-input-group>
                  <flat-pickr class="w-full flatpickr-input rounded-none text-xs" v-model="filter.periode_awal"></flat-pickr>
                </vx-input-group>
                <div class="text-xs px-2 flex items-center border border-r-0 border-l-0 border-solid rounded-none d-theme-border-grey-light" style="background-color: rgba(121,121,121,0.1); border-color: rgba(71,71,71,0.3)">
                  <span class="p-0">s/d</span>
                </div>
                <vx-input-group>
                  <flat-pickr class="w-full flatpickr-input rounded-none text-xs" v-model="filter.periode_akhir"></flat-pickr>
                </vx-input-group>
              </template>
            </vx-input-group>
          </div>
          <div class="flex items-center">
            <vs-input class="w-full" type="text" placeholder="Search" v-model="filter.search" @keyup.enter="getData"/>
          </div>
          <div class="flex items-center">
            <vs-button class="px-3 text-xs text-center" color="primary" :disabled="loading.getData" @click="onFilter">
              {{ loading.getData ? 'Loading...' : 'Filter' }}
            </vs-button>
          </div>
        </div>
        <div class="flex justify-between border-t border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light" style="background-color: rgba(121,121,121,0.1);">
          <div class="flex items-center justify-start space-x-3 px-2 h-8">
            <p class="text-xs font-medium text-dark text-bold">{{ this.data.items.length }} Result</p>
            <QueryEditor v-if="$can('view_query')" code="PENGAKUAN_PENDAPATAN_IPL"/>
          </div>
        </div>
      </div>

      <!----------- box content ------------->
      <div class="flex-1 border-t-0 border-b border-l-0 border-r-0 border-solid d-theme-border-grey-light">
        <div class="box-content-height overflow-auto">
          <div class="tableSticky">
            <table class="w-full h-full border-collapse">
              <colgroup>
                <col class="w-10"/>
                <col class="w-32"/>
                <col class="w-32"/>
                <col class="w-32"/>
                <col class="w-64"/>
                <col class="w-64"/>
                <col class="w-32"/>
                <col class="w-32"/>
                <col class="w-32"/>
              </colgroup>
              <!-----------TABLE HEAD------------>
              <thead class="font-extrabold border border-solid d-theme-border-grey-light">
                <tr class="d-theme-dark-bg border border-solid d-theme-border-grey-light">
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">Action</th>
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">No. IPL</th>
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">Tgl. Due</th>
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">No. Unit</th>
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">Nama Customer</th>
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">Keterangan</th>
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">Total Nilai</th>
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">Total Bayar</th>
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">Total Sisa</th>
                </tr>
              </thead>
              <!-----------TABLE BODY------------>
              <tbody>
                <tr v-for="(item, index) in data.items" :key="index" class="cursor-pointer hover:font-medium">
                  <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-center" @click.stop>
                    <vs-button size="small" class="px-2" icon-pack="feather" icon="icon-check" @click="showModalIplPosting(item)" v-if="$can('posting_pengakuan_pendapatan')">Posting</vs-button>
                  </td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">{{ item.no_ipl }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">{{ item.tgl_dude }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">{{ item.no_unit }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.nama_customer }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.keterangan }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.total_nilai | idr }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.total_bayar | idr }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.total_sisa | idr }}</td>
                </tr>
                <!--spacer-->
                <tr v-if="data.items.length > 0" class="h-full">
                  <td colspan="9"></td>
                </tr>
              </tbody>
              <!-----------TABLE FOOT------------>
              <tfoot v-if="data.items.length > 0">
                <tr class="d-theme-dark-bg border border-solid d-theme-border-grey-light">
                  <th colspan="6" class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">TOTAL</th>
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ summary.grandTotalNilai | idr }}</th>
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ summary.grandTotalBayar | idr }}</th>
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ summary.grandTotalSisa | idr }}</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>

      <!------------ box footer ------------->
      <div class="flex-none">
        <div class="flex flex-wrap items-center p-2 justify-between">
          <div class="h-8"></div>
        </div>
      </div>
    </div>

    <!--modals-->
    <PengakuanPendapatanIplPosting
      :isActive.sync="modalIplPosting.active"
      :idIpl="modalIplPosting.idIpl"
      @success="refresh"/>
  </div>
</template>

<script>
import ProyekRepository from '@/repositories/master/proyek-repository'
import PengakuanPendapatanRepository from '@/repositories/accounting/pengakuan-pendapatan-repository'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'TabIpl',
  props: ['isActive'],
  components: {
    PengakuanPendapatanIplPosting: () => import('@/views/pages/accounting/pengakuan-pendapatan/part/PengakuanPendapatanIplPosting'),
    QueryEditor: () => import('@/views/components/query-editor/QueryEditor'),
    flatPickr
  },
  watch: {
    isActive: {
      immediate: true,
      handler (val) {
        if (val && !this.isDataInited) this.initData()
      }
    }
  },
  computed: {
    summary () {
      return {
        grandTotalNilai: _.sumBy(this.data.items, item => parseFloat(item.total_nilai || '0')),
        grandTotalBayar: _.sumBy(this.data.items, item => parseFloat(item.total_bayar || '0')),
        grandTotalSisa: _.sumBy(this.data.items, item => parseFloat(item.total_sisa || '0'))
      }
    }
  },
  data () {
    return {
      isDataInited: false,
      proyeks: [],
      loading: {
        getData: false
      },
      filter: {
        id_proyek: null,
        periode_awal: moment().startOf('month').format('YYYY-MM-DD'),
        periode_akhir: moment().endOf('month').format('YYYY-MM-DD'),
        search: null
      },
      data: {
        items: []
      },
      modalIplPosting: {
        active: false,
        idIpl: {}
      }
    }
  },
  methods: {
    initData () {
      this.getProyeks()
      this.isDataInited = true
    },

    onFilter () {
      this.getData()
    },

    getProyeks () {
      const params = {
        externalFilter: JSON.stringify({
          is_pengelola_ipl: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
          if (this.proyeks.length > 0) {
            this.filter.id_proyek = this.proyeks[0].id
            this.refresh()
          }
        })
        .catch(error => {
          console.log(error)
        })
    },

    getData () {
      this.loading = true

      const params = {
        type: 'IPL',
        id_proyek: this.filter.id_proyek,
        periode_awal: this.filter.periode_awal,
        periode_akhir: this.filter.periode_akhir,
        search: this.filter.search
      }
      PengakuanPendapatanRepository.getPendapatan(params)
        .then(response => {
          this.data.items = _.map(response.data.data, item => {
            item.uuid = uuid()
            return item
          })
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading = false
        })
    },

    isTabActive (tabCode) {
      return tabCode === this.tabs.active
    },

    showModalIplPosting (item) {
      this.modalIplPosting.idIpl = item.id_ipl
      this.modalIplPosting.active = true
    },

    refresh () {
      this.data.items = []
      this.getData()
    }
  }
}
</script>

<style scoped>
/*navbar floating*/
.navbar-floating .box-content-height {
  height: calc(100vh - 314px);
}
.navbar-floating.footer-sticky .box-content-height {
  height: calc(100vh - 337px);
}
.navbar-floating.footer-hidden .box-content-height {
  height: calc(100vh - 283px);
}

/*navbar sticky*/
.navbar-sticky .box-content-height {
  height: calc(100vh - 300px);
}
.navbar-sticky.footer-sticky .box-content-height {
  height: calc(100vh - 323px);
}
.navbar-sticky.footer-hidden .box-content-height {
  height: calc(100vh - 269px);
}

/*navbar static*/
.navbar-static .box-content-height {
  height: calc(100vh - 282px);
}
.navbar-static.footer-sticky .box-content-height {
  height: calc(100vh - 305px);
}
.navbar-static.footer-hidden .box-content-height {
  height: calc(100vh - 251px);
}

/*navbar hidden*/
.navbar-hidden .box-content-height {
  height: calc(100vh - 237px);
}
.navbar-hidden.footer-sticky .box-content-height {
  height: calc(100vh - 260px);
}
.navbar-hidden.footer-hidden .box-content-height {
  height: calc(100vh - 206px);
}

/*table sticky*/
.tableSticky {
  overflow: auto;
  height: calc(100vh - 300px);
}
.table-sticky thead {
  position: sticky;
  top: -1px;
  z-index: 1;
}
.table-sticky tfoot {
  position: sticky;
  bottom: -1px;
  z-index: 1;
}
</style>

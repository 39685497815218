import httpClient from '@/utilities/http/http-client'
import _ from 'lodash'

const endpoint = 'api/v1/pengakuanPendapatan'

export default {
  getPendapatan (params) {
    const paramsFiltered = _.omitBy(params, _.isNull)
    const queryParams = paramsFiltered ? `?${new URLSearchParams(paramsFiltered).toString()}` : ''
    return httpClient.get(`${endpoint}/pendapatan${queryParams}`)
  },

  getJurnalUnit (params) {
    return httpClient.post(`${endpoint}/jurnalUnit`, params)
  },

  getJurnalIpl (params) {
    return httpClient.post(`${endpoint}/jurnalIpl`, params)
  },

  postingPendapatanUnit (params) {
    return httpClient.post(`${endpoint}/postingPendapatanUnit`, params)
  },

  postingPendapatanIpl (params) {
    return httpClient.post(`${endpoint}/postingPendapatanIpl`, params)
  }
}
